.Page-layout-div {
  /* background-color: white; */
  position: absolute;
  width: 100%;
  top: 100px;
  color: #333;
}
.company-profile-content {
  background-color: rgb(255, 255, 255);
  margin: 20px;
  min-height: 500px;
  text-align: right;
  font-size: 100%;
  font-weight: 500;
  max-width: 700px;
  margin: auto;
  margin-bottom: 100px;
  padding: 30px;
  animation-name: fade-in-from-left;
  animation-duration: 1400ms;
  /* margin-top: 250px; */
  color: #6b6b6b;
  font-family: Assistant, sans-serif;
}
.email-me {
  text-decoration: none;
  color: #6b6b6b;
  transition: 300ms ease;
  font-weight: 600;
}
.email-me:hover {
  color: #3883c4;

  transition: 300ms ease;
}
.company-profile-content form {
  /* margin-bottom: 50px; */
}
.company-profile-content h2 {
  text-align: right;
  color: #034187;
  padding: 20px;
}
.company-profile-content p {
  padding: 10px;
}
.header-image {
  width: 100%;
  max-height: 250px;
  position: absolute;
  z-index: 0;
  background-color: black;
}
#img-backlight-div {
  /* position: absolute;
  right: 0; */
  /* left: 0; */
  /* top: 200px;
  bottom: 0;
  z-index: 1;
  min-width: 200px;
  height: 100%; */
}
.page-header {
  background-color: rgb(0, 0, 0);
  width: 100%;
  position: relative;
}
.access-navbar {
  text-align: right;
  padding: 10px;
  font-size: 80%;
  font-weight: 400;
}
.access-navbar a {
  color: rgb(47, 125, 207);
}
.col-content-third-row-company-profile {
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: rgb(51, 51, 51);
  background-color: rgba(0, 0, 0, 0.3);

  width: 100%;
  /* border-radius: 5px; */
}
.col-content-third-row-company-profile {
  width: 100%;
  /* animation: pulse 1500ms ease-in; */
  animation-fill-mode: forwards;
  text-align: right;
  padding-top: 100px;
  padding-bottom: 100px;
}

.col-content-third-row-company-profile h1 {
  font-size: 400%;
  /* font-weight: 600; */
  text-shadow: black 1px 1px 1px;
  /* animation: pulse 5000ms ease-in; */
  animation-fill-mode: forwards;
  font-family: Assistant;
  text-align: center;
  padding: 20px;
}
.col-content-third-row-company-profile h3 {
  font-weight: 800;
  text-shadow: black 1px 1px 1px;

  color: #13628d;
}
.third-back-img-company-profile {
  width: 100%;
  position: absolute;
  z-index: 0;
  max-height: 900px;
  height: 100%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fade-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@media (min-width: 900px) {
  .Page-layout-div {
    top: 140px;
  }
  .col-content-third-row-company-profile {
    padding-top: 175px;
    padding-bottom: 175px;
  }
}
