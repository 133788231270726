.navbar button {
  border: none;
  display: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
#hamburger-button-id {
  position: fixed;
  top: 40px;
  left: 10%;
  z-index: 15;
  filter: drop-shadow(1px 1px 1px black);
}
.mobile-phone-icon {
  position: fixed;
  top: 42px;
  left: 25%;
  z-index: 15;
  color: #8a8a8a;
}
.mobile-phone-icon:hover {
  color: #3883c4;
}
.mobile-only-wa-icon {
  position: fixed;
  top: 39px;
  left: 35%;
  z-index: 15;
  color: #8a8a8a;
}
.mobile-only-wa-icon:hover {
  color: #3883c4;
}
.side-nav {
  height: 100%;
  text-align: center;
  width: 0;
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  overflow-x: hidden;
  padding-top: 150px;
  transition: width 600ms;
  overflow-y: hidden;
  filter: drop-shadow(2px 1px 15px black);
}

.side-nav li {
  text-decoration: none;
  padding: 20px;
}

.side-nav li a {
  color: #8a8a8a;
  font-weight: 400;
  transition: 1600ms;
  text-decoration: none;
}
.side-nav a:hover {
  color: #3883c4;
  transition: 600ms;
}
#profile-small-nav-item {
  opacity: 0;
}
#contact-small-nav-item {
  opacity: 0;
}
#home-small-nav-item {
  opacity: 0;
}
.side-nav button {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  font-size: 22px;
  color: rgb(95, 90, 90);
  display: block;
  transition: 0.3s;
  background-color: transparent;
  border: none;
  outline: none;
}

.side-nav .btn-close {
  position: absolute;
  top: 0;
  right: 22px;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
}

.navBarLogo {
  max-width: 170px;
  position: fixed;
  top: 2px;
  right: 20px;
  z-index: 15;
  padding: 1px;
  border: solid rgba(240, 240, 240, 0.1) 0.5px;
}

#topNavbarItems {
  font-family: Assistant, sans-serif;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(250, 250, 250, 1);
  z-index: 10;
  height: 100px;
  filter: drop-shadow(1px 1px 15px black);
}
#topNavbarItems a {
  text-decoration: none;
  color: #8a8a8a;
  transition: 600ms;
  padding: 15px;
}
#topNavbarItems a:hover {
  color: #3883c4;

  transition: 600ms;
  text-decoration: none;
}
#topNavbarItems li img {
  max-width: 25px;
  margin: 1px;
}
#side-search-bar {
  margin-left: 28px;
  margin-bottom: 20px;
}

.access {
  position: fixed;
  top: 60px;
  left: 0px;
  width: 75px;
  z-index: 3500;
  height: 75px;
  background-color: #2f7dcf;
  padding: 10px;
  display: none;
}
@media (max-width: 900px) {
  .navbar button {
    display: block;
  }

  #topNavbarItems NavLink {
    display: none;
  }
  #smaller-display-side-navbar-items {
    display: block;
    transition: 500ms;
  }
  #topNavbarItems a {
    display: none;
    opacity: 0;
  }
}

@media (min-width: 900px) {
  .mobile-phone-icon {
    display: none;
  }
  .access {
    display: block;
  }
  .navBarLogo {
    position: relative;
    max-width: 175px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  #topNavbarItems {
    height: 140px;
    font-weight: 400;
  }
  #topNavbarItems li {
    margin-top: 80px;
  }
  .desktop-navbar-logo-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3000;
    height: 100px;
    display: flex;
    justify-content: center;
  }
  .mobile-only-wa-icon {
    display: none;
  }
}
