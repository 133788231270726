video::-webkit-media-controls {
  display: none !important;
}
.col-content-third-row {
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  filter: drop-shadow(1px 1px 5px black);

  width: 100%;
  border-radius: 5px;
}
.col-content-third-row {
  width: 100%;
  text-align: right;
  padding-top: 150px;
  padding-bottom: 150px;
}

.col-content-third-row h1 {
  font-size: 400%;
  /* font-weight: 600; */
  text-shadow: #000000 1px 1px 1px;
  animation: pulse 4000ms ease-in;
  animation-fill-mode: forwards;
  font-family: Assistant, sans-serif;
  text-align: center;
  padding: 20px;
}
.col-content-third-row h3 {
  font-weight: 700;
  animation: pulse 5000ms ease-in;
  animation-fill-mode: forwards;

  font-size: 175%;
}
.col-content-third-row h4 {
  animation: pulse 7000ms ease-in;
  animation-fill-mode: forwards;
}
