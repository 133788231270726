.contact-form-div {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-left: auto;
  text-align: right;
  color: #034187;
  padding: 50px;
  border: solid #034187 1px;
  border-radius: 5px;
}
.contact-form-div img {
  max-width: 100px;
}
.contact-form-div span {
  padding: 30px 0 0 0;
}
.contact-form-div input {
  padding: 10px;
  color: #333;
  border: solid #034187 1px;
  border-radius: 5px;
  text-align: right;
  margin-top: 1px;
}
.contact-form-div input:focus {
  border: solid #2f7dcf 1px;

  outline: none;
}
.contact-form-div input::placeholder {
  color: rgba(182, 182, 182, 0.3);
}
.contact-form-div button {
  padding: 10px;
  margin: 20px 0 0 0;
  color: #2f7dcf;
  background-color: rgb(255, 255, 255);
  border: solid #034187 1px;
  border-radius: 5px;
  transition: 600ms;
  font-size: 110%;
  cursor: pointer;
  font-weight: 600;
}
.contact-form-div button:hover {
  color: white;
  border: solid #2f7dcf 1px;
  background-color: #2f7dcf;

  transition: 600ms;
}
