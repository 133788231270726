.split-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: wrap; */
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  z-index: 5;
}
.split-row-col-content {
  /* width: 30%; */
  /* padding: 10px; */
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  color: rgb(0, 69, 138, 0.9);
  font-weight: 200;
  max-width: 400px;
  margin: auto;
}
.split-row-container-content-first {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: wrap; */
  flex-wrap: nowrap;

  justify-content: center;
  position: relative;
  z-index: 5;
}

.split-row-col-content-content-first {
  /* width: 30%; */
  /* padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px; */
  color: rgb(0, 69, 138, 0.9);
  max-width: 400px;
  margin: auto;
}
.split-row-col-content-content-first h3 {
  color: rgb(0, 69, 138, 0.9);
  margin-bottom: 10px;
  font-size: 125%;
}
.split-row-col-content-content-first p {
  color: rgba(76, 133, 190, 0.9);
  max-width: 300px;
  margin: auto;
}

.split-row-col-content h3 {
  color: rgb(0, 69, 138, 0.9);
  margin-bottom: 10px;
  font-size: 145%;
  font-weight: 400;
}
.split-row-col-content p {
  color: rgba(76, 133, 190, 0.9);
  max-width: 300px;
  margin: auto;
}
.split-row-col-image-container {
  width: 130%;
  max-width: 1400px;
  position: relative;
  overflow: hidden;
  min-height: 250px;
  background-attachment: scroll;
}
.split-row-col-image {
  width: stretch !important;
  height: stretch;
  position: absolute;
  right: 0px;
  left: 0;
}
.split-row-col-content {
  padding: 35px;
  text-align: right;
}
@media (min-width: 800px) {
  .split-row-col-content {
    /* width: 100%; */
  }
  .split-row-col-image-container {
    width: 60%;
    min-height: 400px;
  }

  .split-row-col-image-container {
    /* width: 100%; */
  }
}
