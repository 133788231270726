* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Assistant", sans-serif;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  overflow-x: hidden;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: solid rgb(0, 0, 0, 0.3) 1px;
  height: 100%;
  position: relative;
}
.col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.first-back-img {
  max-width: 1300px;
  width: 100%;
  position: absolute;
  z-index: 0;
  max-height: 900px;
  height: 100%;
}

.col-content {
  font-weight: 700;
  font-size: larger;
  padding: 30px;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
@media (min-width: 900px) {
  .main {
    margin-top: 140px;
  }
}
video::-webkit-media-controls {
  display: none !important;
}
