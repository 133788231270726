.top-services-component h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #00458a;
  font-size: 225%;
  font-weight: 600;
  font-family: Assistant, sans-serif;
}
.top-services-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  /* text-align: right; */

  /* flex: 1 0; */
}
.top-service-col {
  width: 100%;
  margin-bottom: 30px;
}
.top-service-col h3 {
  font-size: 175%;
  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 100px;
  color: #6b6b6b;

  font-family: Assistant, sans-serif;
  text-align: right;
  font-weight: 400;
}
.top-service-col p {
  font-size: 100%;
  padding: 10px;
  font-family: Assistant, sans-serif;
  color: #6b6b6b;
  text-align: right;
}
.top-service-col + .top-service-col {
  /* max-width: 300px; */
  margin-left: 20px;
  /* text-align: right; */
}
.top-service-content-row {
  color: #6b6b6b;
}
.top-service-content-row-text {
  font-family: Assistant, sans-serif;
  color: #6b6b6b;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 10px;
}
.top-service-content-row-text h3 {
  font-size: 175%;
  font-family: Assistant, sans-serif;
  text-align: right;
  font-weight: 400;
}
.top-service-content-row-text p {
  font-size: 100%;
  font-family: Assistant, sans-serif;
  text-align: right;
}
.top-service-content-row img {
  width: 100%;
}
@media (min-width: 600px) {
  .top-service-col {
    width: 350px;
    /* min-width: 20px; */
  }
  .top-service-content-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .top-service-content-row img {
    /* width: 50%; */
    max-width: 400px;
  }
  .top-service-content-row-text {
    /* width: 50%; */
    max-width: 500px;
  }
  .top-services-component h2 {
    font-size: 250%;
    margin: 50px;
  }
}
/* @media (max-width: 900px) {
  .top-service-col {
    max-width: 550px;
    min-width: 550px;
  } */
/* } */
