.landing-page-form {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  text-align: right;
  color: #034187;
  padding-bottom: 50px;
  margin: auto;
  border-radius: 5px;
}
.service-provide-span {
  font-weight: 500;
  color: rgb(119, 119, 119);
  font-size: 110%;
  /* font-family: Signika, sans-serif; */
  text-align: center;
  margin-top: 30px;
}
.landing-page-form span {
  padding: 30px 0 0 0;
}
.landing-page-form input {
  padding: 10px;
  color: #333;
  border: solid #034187 1px;
  border-radius: 3px;
  text-align: right;
}
.landing-page-form input:focus {
  border: solid #2f7dcf 1px;

  outline: none;
}
.landing-page-form input::placeholder {
  color: rgb(182, 182, 182);
}
.landing-page-form button {
  padding: 10px;
  margin: 20px 0 0 0;
  color: #2f7dcf;
  background-color: rgb(255, 255, 255);
  border: solid #034187 1px;
  transition: 600ms;
  font-size: 110%;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
}
.landing-page-form button:hover {
  color: white;
  border: solid #2f7dcf 1px;
  background-color: #2f7dcf;

  transition: 600ms;
}
#checkbox-id-desktop {
  display: none;
}
#checkbox-id-mobile {
  margin: 5px;
  margin-top: 15px;
}
.landing-page-form p {
  font-size: 85%;
  font-weight: 100;
  color: rgb(119, 119, 119);
}
#form-response-message {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  color: #2f7dcf;
  font-size: large;
  font-weight: 800;
}

.lds-ring {
  /* display: block; */
  position: relative;
  /* padding-top: 10px; */
  /* padding-bottom: 30px; */
  /* padding-left: 45px; */
  display: none;
  /* width: 30px; */
  /* height: 30px; */
  /* left: 0; */
  /* top: 0; */
  /* right: 0; */
  margin: auto;
  padding: 10px;
  padding-right: 30px;
  /* margin-right: 30px; */

  /* padding: 10px; */
}
.lds-ring div {
  /* box-sizing: border-box; */
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: auto;
  border: 3px solid #2f7dcf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2f7dcf transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 950px) {
  .lds-ring {
    /* display: block; */
    position: relative;
    /* padding-top: 10px; */
    /* padding-bottom: 30px; */
    /* padding-left: 45px; */
    display: none;
    /* width: 30px; */
    /* height: 30px; */
    /* left: 0; */
    /* top: 0; */
    /* right: 0; */
    /* margin: auto; */
    margin: 0;
    padding: 0;
    margin-right: 30px;

    /* padding: 10px; */
  }
  .landing-page-form {
    width: 100%;
  }
  .landing-page-form {
    flex-direction: row;
    flex-direction: row-reverse;
    padding-top: 10px;
    max-width: 1000px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
  }
  .landing-page-form input {
    margin-top: 30px;
    border-radius: 3px;
    margin-right: 10px;
  }
  .landing-page-form button {
    margin-top: 30px;
    min-width: 150px;
    border-radius: 3px;
    margin-right: 20px;
  }
  .landing-page-form span {
    display: none;
  }
  .landing-page-form p {
    margin-top: 30px;
    padding: 5px;
    font-size: 85%;
    font-weight: 100;
    color: rgb(119, 119, 119);
  }
  #checkbox-id-mobile {
    display: none;
  }
  #checkbox-id-desktop {
    display: inline-block;
  }
  #form-response-message {
    font-size: 80%;
  }
}
