.inner-col-content-col-1 {
  padding: 30px;
  width: 450px;
  height: 500px;
  background-color: rgb(235, 235, 235);

  color: black;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}
.inner-col-content-col-2 {
  padding: 30px;
  width: 450px;
  height: 500px;
  background-color: rgb(235, 235, 235);
  color: black;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}
.inner-col-content-col-2 h1 {
  padding: 20px;
}

.second-back-img {
  width: 100%;
  height: 100%;
  position: absolute;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.95;
  }
}
.hero-section-img {
  width: 100%;
  position: absolute;
  z-index: 0;
  /* max-height: 900px; */
  height: 100%;
  display: none;

  object-fit: fill;
}
.hero-section-img.video {
  display: block;
}
.hero-section-img.img {
}
@media (min-width: 600px) {
  .hero-section-img.video {
    display: block;
  }
  .hero-section-img.img {
    display: none;
  }
}
